import React, { useEffect, useState, useRef } from 'react';
import {
    Table,
    Button,
    Tag,
    Row,
    Col,
    Tooltip,
    Modal as ModalAntd,
    notification,
} from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    FilterOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from '../../../General/Modal';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import {
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../../api/company';
import {
    getCompanyAllPromos,
    deleteCompanyCoupon,
} from '../../../../api/promo';
import AddPromoForm from '../AddPromoForm/AddPromoForm';
import EditPromoForm from '../EditPromoForm';
import { suscriptionType } from '../../../../utils/general';

import './TablePromos.scss';

const TablePromos = () => {
    const isMounted = useRef(true);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [reloadPromos, setReloadPromos] = useState(true);
    const [dataMenu, setDataMenu] = useState([]);
    const { confirm } = ModalAntd;

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const token = getAccessTokenApi();
        const companyId = getCompanyIdApi();
        getCompanySuscriptionApi(token, companyId)
            .then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        const result = response.result;
                        const type = suscriptionType(result.suscriptionType);
                        if (type === 'Basic') {
                            window.location.href = '/admin';
                        }
                    }
                }
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        if (reloadPromos) {
            const accessToken = getAccessTokenApi();
            const now = moment();
            getCompanyAllPromos(accessToken).then((response) => {
                if (isMounted.current) {
                    if (response) {
                        if (response.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }

                        let dataCompleteMenu = [];
                        response.forEach((element) => {
                            element['key'] = element.couponId;
                            let status = '';

                            if (element.durationType === 'range-dates') {
                                if (
                                    moment(now).isBefore(
                                        moment.unix(element.endDate)
                                    ) &&
                                    moment(now).isSameOrAfter(
                                        moment.unix(element.startDate)
                                    )
                                ) {
                                    status = 'Vigente';
                                } else {
                                    if (
                                        moment(now).isBefore(
                                            moment.unix(element.endDate)
                                        ) &&
                                        moment(now).isSameOrBefore(
                                            moment.unix(element.startDate)
                                        )
                                    ) {
                                        status = 'Pausa';
                                    } else {
                                        status = 'Vencida';
                                    }
                                }
                            } else {
                                const daysPromoArray =
                                    element.applicableDays.split(',');
                                let nowDay = moment().days();
                                if (nowDay === 0) {
                                    //asign sunday value 7
                                    nowDay = 7;
                                }
                                //search rest of the days
                                const found = daysPromoArray.find(
                                    (element) => parseInt(element) === nowDay
                                );

                                if (found) {
                                    // status = 'Vigente';
                                    if (
                                        now.hour() >=
                                            moment
                                                .unix(
                                                    element.timeStart,
                                                    'h:mma'
                                                )
                                                .hour() &&
                                        now.hour() <=
                                            moment
                                                .unix(element.timeEnd, 'h:mma')
                                                .hour()
                                    ) {
                                        status = 'Vigente';
                                    } else {
                                        status = 'Pausa';
                                    }
                                } else {
                                    status = 'Pausa';
                                }
                            }
                            element['status'] = status;

                            element['typePromo'] = getTypePromo(element.type);
                            element['periodType'] = getPeriodPromo(element);
                            dataCompleteMenu.push(element);
                        });
                        // console.log(dataCompleteMenu);
                        setDataMenu(dataCompleteMenu);
                        setReloadPromos(false);
                    }
                }
            });
        }
    }, [reloadPromos]);

    const getTypePromo = (type) => {
        let typeText = '';

        switch (type) {
            case 1:
                typeText = '2x1';
                break;
            case 2:
                typeText = '3x2';
                break;
            case 3:
                typeText = 'Desc. en Precios en Platillo ($)';
                break;
            case 4:
                typeText = 'Desc. en Porcentajes en Platillo (%)';
                break;

            case 5:
                typeText = 'Desc. en Precios en Orden($)';
                break;
            case 6:
                typeText = 'Desc. en Porcentajes Orden(%)';
                break;
            case 7:
                typeText = 'Envio gratis';
                break;

            default:
                return '';
        }

        return typeText;
    };

    const getPeriodPromo = (promo) => {
        let periodType = '';
        if (promo.durationType === 'range-dates') {
            periodType = `${moment
                .unix(promo.startDate)
                .format('DD/MM/YYYY, h:mm a')} - ${moment
                .unix(promo.endDate)
                .format('DD/MM/YYYY, h:mm a')}`;
        } else {
            const daysPromoArray = promo.applicableDays.split(',');

            let daysString = '';
            daysPromoArray.forEach((ele) => {
                switch (ele) {
                    case '1':
                        daysString = `${daysString} Lunes,`;
                        break;
                    case '2':
                        daysString = `${daysString} Martes, `;
                        break;
                    case '3':
                        daysString = `${daysString} Miércoles, `;
                        break;
                    case '4':
                        daysString = `${daysString} Jueves, `;
                        break;
                    case '5':
                        daysString = `${daysString} Viernes, `;
                        break;
                    case '6':
                        daysString = `${daysString} Sábado, `;
                        break;
                    case '7':
                        daysString = `${daysString} Domingo, `;
                        break;
                    default:
                        return '';
                }
            });

            periodType = `${daysString} de ${moment
                .unix(promo.timeStart)
                .format('h:mm a')} - ${moment
                .unix(promo.timeEnd)
                .format('h:mm a')}`;
        }
        return periodType;
    };

    const columns = [
        {
            title: 'Nombre de la promoción',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
            ellipsis: false,
        },
        {
            title: 'Tipo',
            dataIndex: 'typePromo',
            key: 'typePromo',
            filters: [
                { text: '2x1', value: '2x1' },
                { text: '3x2', value: '3x2' },
                {
                    text: 'Desc. en Precios en Platillo ($)',
                    value: 'Desc. en Precios en Platillo ($)',
                },
                {
                    text: 'Desc. en Porcentajes en Platillo (%)',
                    value: 'Desc. en Porcentajes en Platillo (%)',
                },
                {
                    text: 'Desc. en Precios en Orden($)',
                    value: 'Desc. en Precios en Orden($)',
                },
                {
                    text: 'Desc. en Porcentajes Orden(%)',
                    value: 'Desc. en Porcentajes Orden(%)',
                },
                { text: 'Envio gratis', value: 'Envio gratis' },
            ],
            filteredValue: filteredInfo.typePromo || null,
            onFilter: (value, record) => record.typePromo === value,
            sorter: (a, b) => a.typePromo.length - b.typePromo.length,
            sortOrder: sortedInfo.columnKey === 'typePromo' && sortedInfo.order,
            ellipsis: false,
            // render: (typePromo) => <p>{typePromo}</p>,
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                { text: 'Vencida', value: 'Vencida' },
                { text: 'Pausa', value: 'Pausa' },
            ],
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            sorter: (a, b) => a.status.length - b.status.length,
            sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
            ellipsis: false,
            align: 'center',
            render: (statusText) => (
                <Tag
                    color={
                        statusText === 'Vencida'
                            ? 'red'
                            : statusText === 'Pausa'
                            ? 'orange'
                            : 'green'
                    }
                >
                    {statusText.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Disponible',
            key: 'available',
            dataIndex: 'available',
            filters: [
                { text: 'Activa', value: true },
                { text: 'Desactivada', value: false },
            ],
            filteredValue: filteredInfo.available || null,
            onFilter: (value, record) => record.available === value,
            sorter: (a, b) => a.available - b.available,
            sortOrder: sortedInfo.columnKey === 'available' && sortedInfo.order,
            ellipsis: false,
            align: 'center',
            render: (statusText) => (
                <Tag color={statusText === false ? 'red' : 'green'}>
                    {statusText ? <CheckOutlined /> : <CloseOutlined />}
                </Tag>
            ),
        },
        {
            title: 'Periodo',
            key: 'periodType',
            dataIndex: 'periodType',
        },
        {
            title: 'Acciones',
            key: 'key',
            fixed: 'right',
            width: 100,
            render: (key) => (
                <Tooltip title='Editar promoción'>
                    <Button
                        type='primary'
                        onClick={() => handleEditPromo(key)}
                        icon={<EditOutlined />}
                    >
                        Editar
                    </Button>
                </Tooltip>
            ),
        },
    ];

    const handleAddPromo = () => {
        setModalTitle('Añadir nueva promoción');
        setModalContent(
            <AddPromoForm
                setIsModalVisible={setIsModalVisible}
                setReloadPromos={setReloadPromos}
            />
        );
        setIsModalVisible(true);
    };

    const handleEditPromo = (ele) => {
        // console.log(ele);
        setModalTitle('Editar promoción');
        setModalContent(
            <EditPromoForm
                setIsModalVisible={setIsModalVisible}
                setReloadPromos={setReloadPromos}
                idPromo={ele.couponId}
            />
        );
        setIsModalVisible(true);
    };

    const handleDeletePromo = () => {
        // console.log('Borrar');
        confirm({
            title: 'Eliminar promociones',
            icon: <ExclamationCircleOutlined />,
            content:
                '¿Estás seguro que deseas borrar las promociones seleccionadas?, no podrá recuperarla después.',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deletePromosApi();
            },
            onCancel() {},
        });
    };

    const deletePromosApi = async () => {
        const accessToken = getAccessTokenApi();
        const dataDelete = {
            CouponIdList: selectedRowKeys,
        };
        const result = await deleteCompanyCoupon(accessToken, dataDelete);
        //
        if (result?.statusCode === 409) {
            notification['error']({
                message: result.description,
            });
        }
        notification['success']({
            message: 'Se elimino exitosamente.',
        });
        setSelectedRowKeys([]);
        setReloadPromos(true);
    };

    const handleChange = (pagination, filters, sorter) => {
        // console.log('Various parameters', filters);
        // console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    const onSelectChange = (selectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='table-promos'>
            <Row>
                <Col xs={24}>
                    <p
                        style={{
                            textAlign: 'right',
                            textTransform: 'capitalize',
                        }}
                    >
                        {moment().format('dddd LL')}
                    </p>
                </Col>
            </Row>
            <Row className='table-promo__top'>
                <Col md={24} style={{ textAlign: 'right' }}>
                    <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button>

                    <Button
                        onClick={handleAddPromo}
                        type='primary'
                        icon={<PlusOutlined />}
                    >
                        Agregar Promoción
                    </Button>
                </Col>
            </Row>
            <div style={{ marginBottom: 16 }}>
                <span style={{ marginLeft: 8 }}>
                    {hasSelected
                        ? `Seleccionados ${selectedRowKeys.length} elemento(s)`
                        : ''}
                </span>
            </div>

            <Table
                bordered
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataMenu}
                onChange={handleChange}
                loading={reloadPromos}
                scroll={{ x: 950 }}
            />

            <div style={{ marginBottom: 16 }}>
                <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                    size='large'
                    disabled={!hasSelected}
                    onClick={handleDeletePromo}
                >
                    {`Eliminar ${selectedRowKeys.length} ${
                        selectedRowKeys.length > 1 ? 'elementos' : 'elemento'
                    }`}
                </Button>
            </div>

            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                footer={true}
            >
                {modalContent}
            </Modal>
        </div>
    );
};

export default TablePromos;
