import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Collapse,
    Button,
    Typography,
    Spin,
    notification,
    Divider,
    Upload,
} from 'antd';
import { CheckCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { apiKeyStripePublic } from '../../../api/config';
import CheckoutFormStripe from '../CheckoutFormStripe';
import {
    addSubscriptionAdminStripeApi,
    editPaymentMethodSubscriptionApi,
} from '../../../api/subscription';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { messageError } from '../../../utils/general';

const stripePromise = loadStripe(apiKeyStripePublic, {
    locale: 'es-419',
});

const PaymentMethods = (props) => {
    const {
        subscription,
        changePaymentMethod,
        priceId,
        setIsModalVisible,
        card,
        transfer,
        deposite,
        payTypeCurrent,
        currentCard,
    } = props;
    const [paymentMethodCurrent, setPaymentMethodCurrent] = useState('');
    const [activeSpin, setActiveSpin] = useState(true);
    const [stripePaymentMethod, setStripePaymentMethod] = useState('');
    const [laoding, setLaoding] = useState(false);
    const [filesListImages, setFileListImages] = useState([]);
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    useEffect(() => {
        setPaymentMethodCurrent(suscriptionPayType(payTypeCurrent));
    }, [payTypeCurrent]);

    function suscriptionPayType(numberType) {
        let type = 'none';
        switch (numberType) {
            case 1:
                type = 'deposite';
                break;

            case 2:
                type = 'card-subscription';
                break;

            case 3:
                type = 'transfer';
                break;

            default:
                type = 'Basic';
                break;
        }
        return type;
    }

    const handleSavePayment = async (type) => {
        if (subscription) {
            if (type === 'card-subscription') {
                if (stripePaymentMethod === '') {
                    notification['error']({
                        message:
                            'Necesita seleccionar o agregar una tarjeta de crédito o débito',
                    });

                    return;
                }

                //CALL STRIPE
                const data = {
                    PriceId: priceId,
                    PaymentMethodId: stripePaymentMethod,
                };
                setLaoding(true);
                addSubscriptionAdminStripeApi(token, data)
                    .then((response) => {
                        if (response) {
                            if (response.statusCode === 200) {
                                setLaoding(false);

                                notification['success']({
                                    message:
                                        'Se ha creado su suscripción exitosamente.',
                                });
                                setTimeout(() => {
                                    setIsModalVisible(false);
                                    window.location.reload();
                                }, 500);
                            } else {
                                let msgError =
                                    'Ocurrió un error, intentelo mas tarde.';
                                if (
                                    response.statusCode === 404 ||
                                    response.statusCode === 400 ||
                                    response.statusCode === 409
                                ) {
                                    msgError = response.description;
                                }
                                notification['error']({
                                    message: msgError,
                                });
                                setLaoding(false);
                            }
                        } else {
                            notification['error']({
                                message: messageError(),
                            });
                            setLaoding(false);
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }

        if (changePaymentMethod) {
            if (type === 'card-subscription') {
                if (stripePaymentMethod === '') {
                    notification['error']({
                        message:
                            'Necesita seleccionar o agregar una tarjeta de crédito o débito',
                    });

                    return;
                }

                if (priceId === '') {
                    notification['error']({
                        message: 'Ocurrio un error, intentelo mas tarde',
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);

                    return;
                }

                //CALL STRIPE
                const data = {
                    PriceId: priceId,
                    PaymentMethodId: stripePaymentMethod,
                };
                setLaoding(true);
                addSubscriptionAdminStripeApi(token, data)
                    .then((response) => {
                        if (response) {
                            if (response.statusCode === 200) {
                                setLaoding(false);

                                notification['success']({
                                    message:
                                        'Se ha creado su suscripción exitosamente.',
                                });
                                setTimeout(() => {
                                    window.location.reload();
                                }, 500);
                            } else {
                                let msgError =
                                    'Ocurrió un error, intentelo mas tarde.';
                                if (
                                    response.statusCode === 404 ||
                                    response.statusCode === 400 ||
                                    response.statusCode === 409
                                ) {
                                    msgError = response.description;
                                }
                                notification['error']({
                                    message: msgError,
                                });
                                setLaoding(false);
                            }
                        } else {
                            notification['error']({
                                message: messageError(),
                            });
                            setLaoding(false);
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                let dataPackage = {
                    PayType:
                        type === 'deposite'
                            ? 1
                            : type === 'card-subscription'
                            ? 2
                            : type === 'transfer'
                            ? 3
                            : 0,
                };

                if (type === 'transfer') {
                    if (filesListImages.length > 0) {
                        const image = filesListImages[0];
                        const posExtension = image.type.indexOf('/') + 1;
                        const posImageData = image.thumbUrl.indexOf(',') + 1;

                        const jsonImage = {
                            Extension: image.type.substr(posExtension),
                            ImageData: image.thumbUrl.substr(posImageData),
                        };
                        dataPackage['Image'] = jsonImage;
                    } else {
                        notification['error']({
                            message: 'Debe adjuntar un documento de evidencia.',
                        });
                        return;
                    }
                }

                setLaoding(true);
                const result = await editPaymentMethodSubscriptionApi(
                    token,
                    companyId,
                    dataPackage
                );
                setLaoding(false);
                if (result) {
                    if (result.statusCode === 200) {
                        notification['success']({
                            message:
                                'Su método de pago se ha modificado exitosamente.',
                        });
                        window.location.reload();
                    } else {
                        let msgError = 'Ocurrió un error, intentelo mas tarde.';
                        if (
                            result.statusCode === 404 ||
                            result.statusCode === 400 ||
                            result.statusCode === 409
                        ) {
                            msgError = result.description;
                        }
                        notification['error']({
                            message: msgError,
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }
            }
        }
        // setPaymentMethod(type);
    };

    const extraImagesChange = (info) => {
        let fileList = [...info.fileList];

        if (fileList.length > 1) {
            notification['warn']({
                message: 'No puede subir más de 1 imágen.',
            });
        }

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(0, 1); //toma solo las 2 primeras
        // fileList = fileList.slice(-2); // remplaza las pasada y siempre son 2
        setFileListImages(fileList);
    };
    const propsUpload = {
        onRemove: async (file) => {
            const index = filesListImages.indexOf(file);
            const newFileListImage = filesListImages.slice();
            newFileListImage.splice(index, 1);
            setFileListImages(newFileListImage);
            return true;
        },
        beforeUpload: (file) => {
            return false;
        },
        onChange: extraImagesChange,
    };

    return (
        <div className='payment-methods'>
            <Spin spinning={laoding} tip='cargando'>
                <Collapse accordion>
                    {transfer && (
                        <Panel
                            header={
                                <HeaderPanel
                                    title={'Transferencia interbancaria'}
                                    type={'transfer'}
                                    paymentMethod={paymentMethodCurrent}
                                />
                            }
                            key='1'
                        >
                            <Paragraph>
                                Realiza tu pago directamente en nuestra cuenta
                                bancaria. Por favor usa el nombre de tu
                                restaurante como{' '}
                                <Text strong>referencia de pago.</Text>Tu cuenta
                                se activará hasta que se haya recibido el
                                importe en nuestra cuenta
                            </Paragraph>
                            <Divider orientation='left'>
                                Detalles Bancarios
                            </Divider>
                            <InfoCompany
                                title='A Nombre de'
                                description='PLANBSYS, SC'
                            />
                            <InfoCompany title='Banco' description='BBVA' />
                            <InfoCompany
                                title='Número de cuenta'
                                description='0074 5064 83 0116727697'
                            />
                            <InfoCompany
                                title='Clabe'
                                description='012580001167276970'
                            />

                            <Upload
                                {...propsUpload}
                                accept='image/png,image/jpg'
                                multiple
                                listType='picture'
                                fileList={filesListImages}
                                defaultFileList={filesListImages}
                            >
                                <Button icon={<UploadOutlined />}>
                                    Seleccionar Archivo
                                </Button>
                            </Upload>
                            <Button
                                type='primary'
                                danger
                                onClick={() => handleSavePayment('transfer')}
                                style={{ marginTop: 15 }}
                            >
                                Seleccionar
                            </Button>
                        </Panel>
                    )}
                    {deposite && (
                        <Panel
                            header={
                                <HeaderPanel
                                    title={'Depósito en efectivo en ventanilla'}
                                    type={'deposite'}
                                    paymentMethod={paymentMethodCurrent}
                                />
                            }
                            key='2'
                        >
                            <p>Pago en efectivo en establecimiento de plan B</p>
                            <Divider>Dirección</Divider>
                            <Paragraph>
                                Calle El Roble 306 Col. Los Urdiales CP 64430
                            </Paragraph>
                            <Button
                                type='primary'
                                danger
                                onClick={() => handleSavePayment('deposite')}
                            >
                                Seleccionar
                            </Button>
                        </Panel>
                    )}
                    {card && (
                        <Panel
                            header={
                                <HeaderPanel
                                    title={
                                        'Pago con Tarjeta de Crédito o Débito'
                                    }
                                    type={'card-subscription'}
                                    paymentMethod={paymentMethodCurrent}
                                />
                            }
                            key='3'
                        >
                            <p>Pago con Tarjeta de Crédito o Débito</p>
                            <Spin spinning={activeSpin}>
                                <Elements stripe={stripePromise}>
                                    <CheckoutFormStripe
                                        setActiveSpin={setActiveSpin}
                                        setStripePaymentMethod={
                                            setStripePaymentMethod
                                        }
                                        currentCard={currentCard}
                                        subscriptionType
                                    />
                                </Elements>
                            </Spin>
                            <Button
                                type='primary'
                                danger
                                onClick={() =>
                                    handleSavePayment('card-subscription')
                                }
                            >
                                Seleccionar
                            </Button>
                        </Panel>
                    )}
                </Collapse>
            </Spin>
        </div>
    );
};

function HeaderPanel({ title, type, paymentMethod }) {
    const { Title } = Typography;
    return (
        <Title level={5}>
            {title}
            {type === paymentMethod && (
                <CheckCircleTwoTone
                    twoToneColor='#52c41a'
                    style={{ marginLeft: 6 }}
                />
            )}
        </Title>
    );
}

function InfoCompany({ title, description }) {
    const { Paragraph, Text } = Typography;

    return (
        <Paragraph>
            <Text strong>{title}: </Text> {description}
        </Paragraph>
    );
}

PaymentMethods.propTypes = {
    subscription: PropTypes.bool,
    changePaymentMethod: PropTypes.bool,
};

export default PaymentMethods;
